<template>
  <div class="container-cental">
    <div class="google-map" ref="googleMap" style="height: 100%"></div>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import DireccionesService from "@/services/direcciones.service";
import PedidosService from "@/services/pedidos.service";
import EventBus from "@/services/eventBus.js";
import { Helpers } from "@/mixins/Helpers";
export default {
  name: "MapaPedidos",
  mixins: [Helpers],
  data() {
    return {
      pedidosService: null,
      direccionesService:null,
      map: null,
      google: null,
      mapConfig: {
        center: {
          lat: 4.683359696392844,
          lng: -74.08041937443866,
        },
        zoom: 8,
        streetViewControl: false,
        restriction: null,
      },
      center: {
        lat: 4.683359696392844,
        lng: -74.08041937443866,
      },
      puntos: [],
      pedidosUbicacion: [],
      bounds: null,
      poligonoCobertura: [],
    };
  },
  created() {
    this.pedidosService = new PedidosService();
    this.direccionesService = new DireccionesService();
  },
  async mounted() {
    this.fetchAreaCobertura();
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: "AIzaSyCFnBjvp7-DXvPyohgfyFPUmRFH4o1CEUM",
    });
    this.google = googleMapApi;
    this.bounds = new this.google.maps.LatLngBounds();
    this.initializeMap();
  },
  methods: {
    fetchAreaCobertura(){
      this.poligonoCobertura=[...this.direccionesService.getAreaCobertura()]
    },
    getItemsForList() {
      this.pedidosService
        .pedidosAll()
        .then((result) => {
          this.pedidosUbicacion = result.data.data.map((item) => {
            return item;
          });
          this.pintarPuntos();
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar items de la lista",
          });
        });
    },
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      this.addZonaDeCobertura();
      this.getItemsForList();
    },
    addZonaDeCobertura() {
      this.objectPoligonoCobertura = new this.google.maps.Polygon({
        paths: this.poligonoCobertura,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.1,
      });
      this.objectPoligonoCobertura.setMap(this.map);
    },
    addMarker(data) {
      let contentString = `
        <div>
            <p style="padding:0px; margin:0px;margin-top:2px;">${data.codigo_pedido}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${data.fecha} de ${this.secondsToHM(data.hora_inicio)} a ${this.secondsToHM(data.hora_fin)}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${data.direccion}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">torre/mnz: ${data.torre_manzana} apto/casa: ${data.apto_casa}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${data.nombre_recibe}</p>
            <p style="padding:0px; margin:0px;margin-top:2px;">${data.telefono_recibe}</p>
            <div>
                <p style="padding:0px; margin:0px;margin-top:2px;font-weight:600">Productos</p>`
                data.productos.forEach((item)=>{
                  contentString+= `<p style="padding:0px; margin:0px;">${item.cantidad} ${item.producto} - ${item.presentacion} ${item.es_adicion==1?'(adición)':''}</p>`
                })                
            contentString+= `</div>
        </div>
      `;
      const infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });

      const objectMarcador = new this.google.maps.Marker({
        position: {
          lat: data.cy,
          lng: data.cx,
        },
        map: this.map,
        draggable: false,
        title: data.codigo_pedido,
        animation: this.google.maps.Animation.DROP,
      });

      objectMarcador.addListener("click", () => {
        infowindow.open({
          anchor: objectMarcador,
          map: this.map,
          shouldFocus: false,
        });
      });

      this.puntos.push(objectMarcador);
    },
    pintarPuntos() {
      this.puntos = [];
      this.pedidosUbicacion.forEach((item) => {
        this.addMarker(item);
      });
      this.ajustarmapa();
    },
    getBoundFromPoligon() {
      return this.poligonoCobertura.map((item) => {
        return new this.google.maps.LatLng(item.lat, item.lng);
      });
    },
    getBoundFromPuntos() {
      return this.puntos.map((item) => {
        return item.getPosition();
      });
    },
    ajustarmapa() {
      const ubicaciones = this.getBoundFromPoligon();
      //const ubicaciones = this.getBoundFromPuntos();
      ubicaciones.forEach((item) => {
        this.bounds.extend(item);
      });
      this.map.fitBounds(this.bounds);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  height: calc(100vh - 55px);
  padding: 0px;
}
</style>
